import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { isEmpty } from 'lodash';

import { useScrollTrigger } from '@mui/material';
import Slide from '@mui/material/Slide';
import useApplications from '../hooks/useApplications';
import EmptyResult from './EmptyResult';
import { EMPTY_RESULTS_MESSAGES } from '../constants';
import ApplicationsHeader from './ApplicationCardHeader';
import ExpandableApplicationGroup from './ExpandableApplicationGroup';
import removeQuotes from '../utils/removeQuotes';
import ApplicationsTableView from './ApplicationsTableView';
import Stats from './Stats';
import Loading from './Loading';
import CompareView from '../../ResultsPage/components/CompareView';
import ResultsStore from '../../../store/SearchResults';
import useChatRIAConfig from '../../../components/ChatRIA/hooks/useChatRIAConfig';
import LanguageToggleText from '../../../components/LanguageToggleComponents/LanguageToggleText';
import useEntities from '../hooks/useEntities';

import VirtualizedGrid from './CardsVirtualScroll';
import useUpdateModule from '../../../hooks/useUpdateModule';
import LabelSectionSelectionDialog from './LabelSectionSelectionDialog';
import DownloadLabelSection from './DownloadLabelSection';
import SearchSuggestions from './SearchSuggestions';

import styles from '../styles/ApplicationsGrid.styles';

const HideOnScroll = ({ scrollElement, children }: any) => {
  const trigger = useScrollTrigger({
    target: scrollElement ?? undefined
  });
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};
const Applications = () => {
  // Needs to be broken to grouped view and list view.
  const { deleteAllChatRIAConfig } = useChatRIAConfig();

  const [scrollViewHeightStyle, setScrollViewHeightStyle] = useState('100vh');

  useUpdateModule('applications');

  useEffect(() => {
    return () => {
      deleteAllChatRIAConfig();
    };
  }, []);

  const {
    applications,
    sortBy,
    sortOptions,
    groupByOptions,
    groupBy,
    isGroupByFilterApplied,
    selectedGroupOption,
    appView,
    resultedSources,
    searchTerm,
    stats,
    hideApplication,
    selectHiddenApplication,
    restoreApplications,
    triggerChatRIA,
    setFavorite,
    setInProject,
    setIsSubscribed,
    searchType,
    labelComparisonSelection,
    comparisonApplications,
    exitCompareMode,
    clearComparison,
    removeFromComparison,
    handleCompareView,
    feature,
    sources,
    handleLabelSection,
    selectedLabelSelection,
    isDownloadLabelSummaryOn
  } = useApplications();
  const {
    selectedSources,
    handleSourceChange,
    makeSearch,
    entityCategory,
    entityCategoryOptions,
    applyCategoryEntity,
    entityValue,
    entityTextOptions,
    applyEntityText
  } = useEntities();
  const { resultsState } = useContext(ResultsStore);
  const [scrollElement, setScrollElement] = useState<any>(null);
  const [scrollRef, setScrollRef] = useState<any>(null);

  const resizeScrollView = () => {
    const appViewHeaderHeight =
      document.getElementById('application-view-header')?.clientHeight ?? 0;
    const pageHeaderHeight = document.getElementsByTagName('header')?.[0]?.clientHeight ?? 0;

    setScrollViewHeightStyle(`calc(93vh - ${appViewHeaderHeight + pageHeaderHeight}px)`);
  };

  const handleAppHeaderResize = () => {
    resizeScrollView();
  };

  useEffect(() => {
    resizeScrollView();
  }, [applications, entityCategoryOptions, entityValue, entityCategory, resultsState.filters]);

  useEffect(() => {
    setScrollElement(scrollRef);
  }, [appView, labelComparisonSelection, scrollRef]);

  if (resultsState?.isContentLoading) {
    return <Loading />;
  }

  // If there are no applications and no filters applied, show search suggestions if available
  if (
    isEmpty(applications) &&
    isEmpty(resultsState.filters) &&
    resultsState?.searchSuggestions?.length > 0
  ) {
    return <SearchSuggestions />;
  }

  if (isEmpty(applications)) {
    return (
      <EmptyResult
        firstMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.firstMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.firstMessage
        }
        secondMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.secondMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.secondMessage
        }
      />
    );
  }

  return (
    <Box>
      <HideOnScroll scrollElement={scrollElement}>
        <Grid
          container
          mt={0}
          id='application-view-header'
          sx={{
            position: 'sticky !important',
            backgroundColor: 'white',
            background: 'white',
            zIndex: 1
          }}>
          <Grid
            item
            xs={12}
            pt='6px !important'
            pb={0}
            mx={2}
            borderBottom='1px solid rgba(0, 0, 0, 0.05)'>
            <ApplicationsHeader
              sortOptions={sortOptions}
              onSortOptionChange={sortBy}
              groupByOptions={groupByOptions}
              onGroupByOptionChange={groupBy}
              selectHiddenApplication={selectHiddenApplication}
              selectedGroupOption={selectedGroupOption}
              restoreApplications={restoreApplications}
              isGroupByFilterApplied={isGroupByFilterApplied}
              showGroupBy={appView === 'grid'}
              entityCategory={entityCategory}
              entityValue={entityValue}
              entityCategoryOptions={entityCategoryOptions}
              entityTextOptions={entityTextOptions}
              applyCategoryEntity={applyCategoryEntity}
              applyEntityText={applyEntityText}
              selectedSources={selectedSources}
              handleSourceChange={handleSourceChange}
              makeSearch={makeSearch}
              searchType={searchType}
              feature={feature}
              stats={stats}
              isLabelComparisionSelection={resultsState.labelComparisonSelection}
              isDownloadLabelSummaryOn={isDownloadLabelSummaryOn}
              onResize={handleAppHeaderResize}
              totalCategory={applications?.length}
            />
          </Grid>
          <Grid item ml={2} width='100%' sx={{ mt: 1 }}>
            <Stack direction='row' justifyContent='space-between' width='100%'>
              {!['505b2'].includes(feature) && <Stats stats={stats} />}
              {resultsState.originalTextEnabled &&
                !labelComparisonSelection &&
                !isDownloadLabelSummaryOn && <LanguageToggleText />}
              {labelComparisonSelection ? (
                <CompareView
                  compareMode={labelComparisonSelection}
                  applications={comparisonApplications}
                  exitCompareMode={exitCompareMode}
                  clearComparison={clearComparison}
                  removeFromComparison={removeFromComparison}
                  feature={feature}
                  sources={sources}
                />
              ) : null}
              {resultsState.collatedSummaryApplications.length !== 0 ? (
                <DownloadLabelSection />
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </HideOnScroll>

      {appView === 'grid' || labelComparisonSelection ? ( // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!isGroupByFilterApplied ? (
            <Stack mb={10} sx={{ overflowY: 'auto' }}>
              {Array.isArray(applications) && (
                <>
                  {applications?.filter((app: any) => app.relevance_score !== 'suggested')
                    .length === 0 && (
                    <EmptyResult
                      height='20vh'
                      marginTop={2.5}
                      iconStyling={{
                        width: 64,
                        height: 64,
                        fontSize: 30
                      }}
                      firstMessage="Sorry, we couldn't find exact matches for your search."
                      secondMessage='However, check out RIA Suggestions you might like!'
                    />
                  )}
                  <Grid
                    container
                    spacing={2.5}
                    pt={1}
                    pr={2.5}
                    width='100% !important'
                    maxWidth='lg-md'
                    sx={styles.gridContianer}
                    mx='auto'>
                    <VirtualizedGrid
                      applications={applications}
                      hideApplication={hideApplication}
                      triggerChatRIA={triggerChatRIA}
                      setFavorite={setFavorite}
                      setInProject={setInProject}
                      setIsSubscribed={setIsSubscribed}
                      handleCompareView={handleCompareView}
                      labelComparisonSelection={labelComparisonSelection}
                      labelComparisonLimit={
                        resultsState?.labelComparisonSelectionMethod?.limit ?? 0
                      }
                      comparisonApplications={comparisonApplications}
                      height={scrollViewHeightStyle}
                      handleLabelSection={handleLabelSection}
                      scrollerRef={setScrollRef}
                      selectedLabelSelection={selectedLabelSelection}
                    />
                  </Grid>
                </>
              )}
            </Stack>
          ) : (
            <Box
              px={2}
              mt='-95px'
              pt='95px'
              ref={(node: any) => {
                setScrollRef(node);
              }}
              sx={{
                maxHeight: scrollViewHeightStyle,
                overflowY: 'auto'
              }}>
              {applications &&
                Object.keys(applications).map((group: any) => (
                  <ExpandableApplicationGroup
                    key={group}
                    group={removeQuotes(group)}
                    keyMapper={selectedGroupOption?.keyMapper}
                    applications={(applications as any)[group]}
                    hideApplication={hideApplication}
                    triggerChatRIA={triggerChatRIA}
                    setFavoriteInHook={setFavorite}
                    setInProjectInHook={setInProject}
                    setSubscriptionInHook={setIsSubscribed}
                    handleCompareView={handleCompareView}
                    showCompare={labelComparisonSelection}
                    comparisonApplications={comparisonApplications}
                    labelComparisonLimit={resultsState?.labelComparisonSelectionMethod?.limit ?? 0}
                    selectedLabelSelection={selectedLabelSelection}
                    handleLabelSection={handleLabelSection}
                  />
                ))}
            </Box>
          )}
        </>
      ) : (
        <ApplicationsTableView
          applications={applications}
          sources={resultedSources}
          searchTerm={searchTerm}
        />
      )}
      <LabelSectionSelectionDialog />
    </Box>
  );
};

export default React.memo(Applications);
