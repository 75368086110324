// @ts-nocheck
import { memo, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

// mui
import {
  Autocomplete,
  CircularProgress,
  Grow,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Clear } from '@mui/icons-material';

// types
import { SearchBarAutoCompleteProps, SearchSuggestionsResult } from '../types';

// custom icons
import { DiagonalArrowIcon, SearchIcon, TimeIcon } from '../../../assets/svgs/Icons';

// styles
import styles from '../styles/SearchBarAutoComplete.styles';

const SearchBarAutoComplete = ({
  id,
  label,
  onInputChange,
  setSearch,
  open,
  onOpen,
  onClose,
  loading,
  options = [],
  value,
  filterOptions,
  onFocus,
  noIcon,
  clearSearchSuggestions,
  disableSearch = false,
  type,
  handleSearch,
  handleClearSearch
}: SearchBarAutoCompleteProps) => {
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const [maxHeight, setMaxHeight] = useState(400); // Initial maxHeight value
  const handleOnChange = (event: SyntheticEvent<Element, Event> | null, newValue: string) => {
    // event could be null when we set a value directly
    if (event) {
      onInputChange?.(newValue);
    }
    setSearch?.(newValue);
  };

  const onChangeWrapper = (
    _event: SyntheticEvent<Element, Event> | null,
    newValue: string | SearchSuggestionsResult
  ) => {
    if (typeof newValue === 'string') handleSearch?.(newValue);
    else handleSearch?.(newValue?.title);
  };

  useEffect(() => {
    const calculateHeightofPopup = () => {
      if (!autoCompleteRef?.current) return;
      const rect = autoCompleteRef.current.getBoundingClientRect();
      // Calculate the maxHeight based on the distance to the bottom of the screen
      const distanceToBottom = window.innerHeight - rect.bottom;
      const calculatedMaxHeight = Math.min(400, distanceToBottom - 50); // Adjust 50 for bottom margin
      setMaxHeight(calculatedMaxHeight);
    };
    const debouncedCalculateHeight = debounce(calculateHeightofPopup, 300); // Adjust debounce time as needed
    calculateHeightofPopup();
    window.addEventListener('resize', debouncedCalculateHeight);
    return () => {
      window.removeEventListener('resize', debouncedCalculateHeight);
    };
  }, [autoCompleteRef?.current]);

  return (
    <Autocomplete
      id={id}
      ref={autoCompleteRef}
      autoFocus
      freeSolo
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      loading={loading}
      options={options}
      value={value}
      filterOptions={filterOptions}
      onInputChange={handleOnChange}
      onChange={onChangeWrapper}
      onFocus={onFocus}
      disabled={disableSearch}
      getOptionLabel={option => (typeof option === 'string' ? option : option.title)}
      getOptionDisabled={option => option.type === 'placeholder'}
      disableClearable
      sx={
        type === 'nav' || type === 'aria'
          ? {
              ...styles.searchNav,
              '& .MuiAutocomplete-popper': {
                backgroundColor: 'red'
              }
            }
          : {
              ...styles.search,
              '& .MuiAutocomplete-popper': {
                backgroundColor: 'red'
              }
            }
      }
      ListboxProps={{ style: { maxHeight: `${maxHeight}px` } }}
      componentsProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 11]
              }
            },
            {
              name: 'flip',
              enabled: false
            }
          ]
        }
      }}
      renderInput={params => {
        return (
          <TextField
            ref={params.InputProps.ref}
            required
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={label}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 13 },
              endAdornment: (
                <InputAdornment
                  position='end'
                  sx={type !== 'nav' && noIcon ? { display: 'none' } : {}}>
                  <Grow in={value?.length > 0} unmountOnExit>
                    <IconButton
                      sx={{
                        p: 0,
                        mr: 1
                      }}
                      onClick={() => {
                        handleOnChange(null, '');
                        handleClearSearch?.();
                        onClose();
                        clearSearchSuggestions();
                      }}>
                      <Clear sx={{ fontSize: 18, color: 'gray.600' }} />
                    </IconButton>
                  </Grow>
                  {type === 'nav' &&
                    (loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : (
                      <IconButton type='submit' disabled={disableSearch} sx={styles.searchButton}>
                        <SearchIcon style={{ fontSize: 12, color: 'gray.50' }} />
                      </IconButton>
                    ))}
                  {type === 'aria' &&
                    (loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : (
                      <IconButton
                        type='submit'
                        disabled={disableSearch}
                        sx={styles.searchButtonAria}>
                        <SearchIcon fontSize='inherit' />
                      </IconButton>
                    ))}
                </InputAdornment>
              )
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <Stack
          direction='row'
          spacing={2}
          component='li'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}>
          {option.type === 'history' && <TimeIcon sx={styles.greyIcon} />}
          {option.type === 'suggestion' && <SearchIcon sx={styles.greyIcon} />}
          <Typography
            variant='subtitle2'
            sx={option.type === 'history' ? styles.greenText : styles.normalText}>
            {option.title}
          </Typography>
          {option.type !== 'placeholder' && (
            <IconButton
              title='Copy to search'
              onClick={e => {
                e.stopPropagation();
                setSearch?.(option.title);
              }}>
              <DiagonalArrowIcon sx={styles.greyIcon} />
            </IconButton>
          )}
        </Stack>
      )}
    />
  );
};

SearchBarAutoComplete.defaultProps = {
  onInputChange: () => {},
  setSearch: () => {},
  options: [],
  filterOptions: (options: { label: string; value: string }[]) => options,
  onFocus: () => {},
  noIcon: false,
  disableSearch: false
};

export default memo(SearchBarAutoComplete);
