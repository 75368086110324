import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SkeletonCard = () => {
  return (
    <Grid item xs={12} md={6} lg={3} xl={3} pl={2.5} pt={2.5} pr={0.5}>
      <Stack spacing={2}>
        <Skeleton variant='text' width='50%' />
        <Skeleton
          variant='rectangular'
          sx={{
            marginTop: 1,
            width: '100%',
            height: 180,
            borderRadius: 10,
            transition: 'transform .2s',
            '&:hover': {
              transform: 'scale(1.1)'
            }
          }}
        />
      </Stack>
    </Grid>
  );
};

export default React.memo(SkeletonCard);
