/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Grid, Stack, Box, Typography } from '@mui/material';
import { VirtuosoGrid } from 'react-virtuoso';

import { ListContainer } from '../../ResultsPage/styledComponents/VirtualListContainers';
import SkeletonCard from '../../ResultsPage/components/SkeletonCard';

import Card from './Card';

interface CardContainerProps {
  children: React.ReactNode;
  customProp: { renderType?: string }[];
  'data-index'?: number; // Add data-index to the prop types
}

const CardContainer = ({ children, customProp, ...props }: CardContainerProps) => {
  const dataIndex = props['data-index'] || 0;
  const renderType = customProp[dataIndex]?.renderType;

  return (
    <Grid
      item
      xs={12}
      md={renderType === 'heading' ? 12 : 6}
      lg={renderType === 'heading' ? 12 : 3}
      xl={renderType === 'heading' ? 12 : 3}
      pl={2.5}
      pt={2.5}
      pr={0.5}>
      {children}
    </Grid>
  );
};

CardContainer.defaultProps = {
  'data-index': 0 // Default value for data-index
};

// Wrapper component to inject custom prop
const CardContainerWrapper =
  (customProp: any[]) =>
  ({ children, ...props }: { children: React.ReactNode }) =>
    (
      <CardContainer customProp={customProp} {...props}>
        {children}
      </CardContainer>
    );

const VirtualizedGrid = ({
  applications,
  hideApplication,
  triggerChatRIA,
  setFavorite,
  setInProject,
  setIsSubscribed,
  handleCompareView,
  labelComparisonSelection,
  labelComparisonLimit,
  comparisonApplications,
  height,
  handleLabelSection,
  scrollerRef,
  selectedLabelSelection
}: any) => {
  const [combinedApplications, setCombinedApplications] = useState([]) as any;

  useEffect(() => {
    const relevantApplications = applications.filter(
      (app: any) => app.relevance_score !== 'suggested'
    );
    const suggestedApplications = applications.filter(
      (app: any) => app.relevance_score === 'suggested'
    );

    const updatedCombinedApplications = [
      ...relevantApplications,
      ...(suggestedApplications.length > 0
        ? [{ renderType: 'heading', content: 'Suggested results' }]
        : []),
      ...suggestedApplications
    ];

    setCombinedApplications(updatedCombinedApplications);
  }, [applications]);

  return (
    <VirtuosoGrid
      scrollerRef={ref => {
        // eslint-disable-next-line no-param-reassign
        scrollerRef(ref);
      }}
      className='virtualgrid'
      style={{
        height: height ?? '100vh',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      totalCount={combinedApplications.length}
      overscan={3000}
      components={{
        Item: CardContainerWrapper(combinedApplications) as any,
        List: ListContainer as any,
        // eslint-disable-next-line react/no-unstable-nested-components
        ScrollSeekPlaceholder: () => <SkeletonCard />
      }}
      scrollSeekConfiguration={{
        enter: velocity => Math.abs(velocity) > 100,
        exit: velocity => {
          return Math.abs(velocity) < 10;
        }
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      itemContent={(index: number) => {
        const data = combinedApplications[index];
        if (data.renderType === 'heading') {
          return (
            <Stack
              mt={10}
              direction='row'
              justifyContent='flex-start'
              width='100%'
              sx={{ bgcolor: 'gray.200', borderRadius: '6px', px: 1.5, py: 0.5 }}>
              <Typography fontSize={13} sx={{ fontWeight: 700, color: 'gray.800' }}>
                Similar Results You Might Like!
              </Typography>
            </Stack>
          );
        }
        return (
          <Box>
            <Card
              data={data}
              showDottedTitle={
                data.groupTitle?.toLowerCase() ===
                combinedApplications[index - 1]?.groupTitle?.toLowerCase()
              }
              applicationIndex={index}
              hideApplication={hideApplication}
              triggerChatRIA={triggerChatRIA}
              setFavoriteInHook={setFavorite}
              setInProjectInHook={setInProject}
              setSubscriptionInHook={setIsSubscribed}
              handleCompareView={handleCompareView}
              showCompare={labelComparisonSelection}
              inCompare={
                comparisonApplications?.some((d: any) => d.identifier === data.identifier) ?? false
              }
              disableCompare={comparisonApplications?.length >= labelComparisonLimit}
              handleLabelSection={handleLabelSection}
              inLabelSection={
                selectedLabelSelection?.some(
                  (d: any) => d.labelSelectionIdentifier === data.labelSelectionIdentifier
                ) ?? false
              }
            />
          </Box>
        );
      }}
    />
  );
};

export default React.memo(VirtualizedGrid);
