const styles = {
  gridContianer: {
    '*::-webkit-scrollbar': {
      width: '6px'
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'gray.scrollBarBg'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.scrollBarThumb',
      borderRadius: '8px'
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'gray.scrollBarThumb'
  }
};

export default styles;
