import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Typography,
  DialogContent,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grow,
  Radio,
  RadioGroup,
  Tooltip,
  Stack,
  Switch,
  Badge
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AddCircleOutlined, Close } from '@mui/icons-material';
import { v4 } from 'uuid';
import { useHistory, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import AdvancedSearchItems from './AdvancedSearchItems';
import { getCategories } from '../../../api/modules/advancedSearch';
import AdvancedSearchLoading from './AdvancedSearchLoading';
import GlobalActions from '../../../store/actions';
import {
  cnfToAdvancedSearch,
  generateCNFQuery,
  handleAdvancedSearch
} from '../../../components/Header/utils/advanceSearchHelpers';
import Store from '../../../store';
import SearchIcon from '../../../assets/svgs/Icons/SearchIcon';
import AdvanceSearchIcon from '../../../assets/svgs/Icons/AdvanceSearchIcon';
import styles from '../styles/SearchBar.styles';
import { AdvancedSearchProps, AdvancedSearchRow, AdvancedSearchValue } from '../types';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import { ADVANCED_SEARCH_SCOPE_LIST, ADVANCED_SEARCH_REGION_LIST } from '../const';
import { findRelevantAdvancedSearchScopeRegion } from '../utils';
import getDate from '../../../utils/getDate';
import AdvanceSearchTitleText from './AdvanceSearchTiteText';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import AdvanceSearchAlert from './AdvanceSearchAlert';

const AdvancedSearch = ({
  selectedSources,
  type = 'iconButton',
  active = false
}: AdvancedSearchProps) => {
  const { dispatch } = useContext<any>(Store);
  const location = useLocation();
  const history = useHistory();
  const [categoryOptions, setCategoryOptions] = useState<any>([
    {
      id: '',
      label: ''
    }
  ]);
  const [open, setOpen] = useState(false);
  const [scope, setScope] = useState('biologics');
  const [region, setRegion] = useState('us');
  const [source, setsource] = useState('sba');
  const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearchValue>({
    startDate: new Date(1900, 0, 1),
    endDate: new Date(),
    useSynonyms: true
  });
  const [advancedSearchTerm, setAdvancedSearchTerm] = useState<AdvancedSearchRow[]>([
    {
      category: '',
      condition: 'contains',
      subCategory: [],
      searchTerm: '',
      categoryKey: '',
      id: v4(),
      createdOrder: 0,
      exactMatch: false,
      compareCondition: 'AND'
    }
  ]);
  const [disabled, setDisabled] = useState(true);
  const [highlightRowIds, setHighlightRowIds] = useState<number[]>([]);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  // eslint-disable-next-line no-unused-vars
  const onClose = (event: any = null, reason: any = '') => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
  };

  const handleAlertClose = () => {
    resultsDispatch({ type: ResultActions.SET_ADVANCE_SEARCH_ALERT_MSG, value: false });
  };

  const getSourceMappingPayload = () => {
    const sources: any = {};

    if (scope === 'clinicalTrials' && region === 'us') {
      sources.ct = [source];
    } else if (region === 'hma') {
      sources.eu = [source];
    } else {
      sources[region] = [source];
    }
    return sources;
  };
  const addNewRow = () => {
    setAdvancedSearchTerm((prev: any) => {
      prev.push({
        category: '',
        condition: 'contains',
        searchTerm: '',
        subCategory: [],
        categoryKey: '',
        id: v4(),
        createdOrder: prev.length,
        exactMatch: false,
        compareCondition: 'AND'
      });
      return [...prev];
    });
  };

  const hasDuplicates = (advancedSearchTermList: AdvancedSearchRow[]) => {
    return advancedSearchTermList.some((term, index) => {
      const searchTermLower = term.searchTerm.trim().toLowerCase();
      const categoryLower = term.category.trim().toLowerCase();

      return advancedSearchTermList.some((otherTerm, otherIndex) => {
        if (otherIndex <= index) return false; // Skip already checked terms
        return (
          searchTermLower === otherTerm.searchTerm.trim().toLowerCase() &&
          categoryLower === otherTerm.category.trim().toLowerCase() &&
          term.subCategory.every(subCat => otherTerm.subCategory.includes(subCat))
        );
      });
    });
  };

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const localAdvancedSearch = { ...advancedSearch };
      const checkIfContainsExist = advancedSearchTerm.some(
        ({ condition }) => condition === 'contains' || condition === 'mayContain'
      );
      if (!checkIfContainsExist) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            message: 'At least one row should have "Contains" as the condition',
            status: true
          }
        });
        return;
      }

      const checkIfDuplicateExist = hasDuplicates(advancedSearchTerm);
      if (checkIfDuplicateExist) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            message: 'Duplicate rows are not allowed',
            status: true
          }
        });
        return;
      }
      // Check if for any row exact match is false
      const listDataAfterExactMatch = advancedSearchTerm.map(
        ({ exactMatch, searchTerm, ...rest }) => ({
          searchTerm: exactMatch ? `"${searchTerm}"` : searchTerm,
          exactMatch,
          ...rest
        })
      );
      // check if 'approval_date' is selected
      const approvalDate = listDataAfterExactMatch.filter(
        ({ category }) => category === 'approval_date'
      );
      if (
        approvalDate.length <= 0 &&
        localAdvancedSearch?.startDate &&
        localAdvancedSearch?.endDate
      ) {
        // @ts-ignore
        delete localAdvancedSearch.startDate;
        // @ts-ignore
        delete localAdvancedSearch.endDate;
      }
      const url = handleAdvancedSearch(
        listDataAfterExactMatch,
        localAdvancedSearch,
        getSourceMappingPayload()
      );

      if (Object.keys(resultsState?.filters)?.length > 0) {
        // filters need to be reset with each new advance search
        // this will reset the applied bottom filters and synonym filters when user is trying to execute new advance search
        resultsDispatch({ type: ResultActions.SET_FILTERS, value: {} });
        // search api was calling twice when executing new advance search from result page
        resultsDispatch({ type: ResultActions.SET_IS_NEW_ADVANCE_SEARCH, value: true });
      }
      history.push(url);
      onClose();
    },
    [history, onClose, advancedSearch, advancedSearchTerm, dispatch]
  );

  useEffect(() => {
    const labelsectionList: Array<any> = [];
    advancedSearchTerm.forEach((item: AdvancedSearchRow) => {
      if (item.subCategory && item.subCategory.length !== 0) {
        item.subCategory.forEach(section => {
          labelsectionList.push(section);
        });
      }
    });
    dispatch({
      type: GlobalActions.SET_LABEL_SECTION_LIST_ADVANCE_SEARCH,
      value: labelsectionList
    });
  }, [advancedSearchTerm]);

  const disableAddCheck = useMemo(() => {
    return (
      Object.values(advancedSearchTerm).filter(
        a =>
          a.category === '' ||
          (a.searchTerm === '' && a.category !== 'approval_date') ||
          (a.category === 'approval_date' && (!advancedSearch.startDate || !advancedSearch.endDate))
      ).length > 0
    );
  }, [advancedSearchTerm]);

  const tooltipTitle = useMemo(() => {
    if (type === 'text') return 'Show Advanced Search query';
    return 'Advanced search';
  }, [type]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScope((event.target as HTMLInputElement).value);
    ADVANCED_SEARCH_REGION_LIST.forEach((item: any) => {
      if (item.value === region) {
        setsource(item.module[(event.target as HTMLInputElement).value]);
      }
    });
  };

  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegion((event.target as HTMLInputElement).value);
    ADVANCED_SEARCH_REGION_LIST.forEach((item: any) => {
      if (item.value === (event.target as HTMLInputElement).value && scope in item.module) {
        setsource(item.module[scope]);
      }
    });
  };

  useEffect(() => {
    if (open) {
      getCategories({ source: getSourceMappingPayload() })
        .then((res: any) => {
          setCategoryOptions(res?.data?.body?.result);
          setDisabled(false);
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setDisabled(false);
        });
      setAdvancedSearchTerm([
        {
          category: '',
          condition: 'contains',
          subCategory: [],
          searchTerm: '',
          categoryKey: '',
          id: v4(),
          createdOrder: 0,
          exactMatch: false,
          compareCondition: 'AND'
        }
      ]);
    }
  }, [open, region, source, scope]);

  useEffect(() => {
    if (!selectedSources) return;
    const relevantScopeRegion = findRelevantAdvancedSearchScopeRegion(selectedSources);
    if (relevantScopeRegion && relevantScopeRegion.length > 0) {
      setScope(relevantScopeRegion[0]?.scope?.value ?? '');
      ADVANCED_SEARCH_REGION_LIST.forEach((item: any) => {
        if (item.value === relevantScopeRegion[0]?.region?.value) {
          setsource(item.module[relevantScopeRegion[0]?.scope?.value]);
        }
      });
      setRegion(relevantScopeRegion[0]?.region?.value ?? '');
    }
  }, [selectedSources]);

  const getQueryText = () => {
    const queryText: any = generateCNFQuery(
      advancedSearchTerm,
      categoryOptions,
      advancedSearch,
      true
    );
    const title: Array<string> = [];
    // removing starting and ending '(' and ')' symbol and adding '(' and  ')' to each query term except operators
    if (queryText && queryText.length !== 0) {
      queryText.forEach((item: any) => {
        if (![' ( ', ' ) '].includes(item)) {
          if (item.includes(':')) {
            title.push(`(${item})`);
          } else {
            title.push(item);
          }
        }
      });
    }
    return title;
  };

  useEffect(() => {
    if (location.pathname.startsWith('/search')) {
      const encodedPayload = location.pathname.split('/search/')[1];
      const searchPayload: any = decodeBase64ToObject(encodedPayload);

      if (searchPayload?.search_type === 'advanced') {
        const newStartDate = getDate(
          searchPayload?.approval_date?.start_date &&
            searchPayload?.approval_date?.start_date !== 'Invalid Date'
            ? searchPayload?.approval_date?.start_date
            : new Date(1900, 0, 2)
        );
        const newEndDate = getDate(
          searchPayload?.approval_date?.end_date &&
            searchPayload?.approval_date?.end_date !== 'Invalid Date'
            ? searchPayload?.approval_date?.end_date
            : new Date()
        );
        setAdvancedSearch({
          startDate: newStartDate,
          endDate: newEndDate,
          useSynonyms: searchPayload?.use_synonyms
        });
        const newSearchTerms = cnfToAdvancedSearch(searchPayload?.search_term ?? null) ?? [];
        if (searchPayload?.approval_date?.start_date) {
          newSearchTerms.push({
            category: 'approval_date',
            condition: 'contains',
            searchTerm: '',
            categoryKey: 'approval_date',
            id: v4(),
            createdOrder: newSearchTerms.length,
            exactMatch: false
          });
        }
        if (newSearchTerms.length === 0) {
          newSearchTerms.push({
            category: '',
            condition: 'contains',
            searchTerm: '',
            categoryKey: '',
            id: v4(),
            createdOrder: 0,
            exactMatch: false
          });
        }
        setAdvancedSearchTerm(newSearchTerms);
      } else {
        setAdvancedSearch({
          startDate: new Date(1900, 0, 1),
          endDate: new Date(),
          useSynonyms: true
        });
        setAdvancedSearchTerm([
          {
            category: '',
            condition: 'contains',
            searchTerm: '',
            subCategory: [],
            categoryKey: '',
            id: v4(),
            createdOrder: 0,
            exactMatch: false,
            compareCondition: 'AND'
          }
        ]);
      }
    }
  }, [location.pathname, open]);

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <span style={{ background: 'white', zIndex: 2 }}>
          {type === 'nav' && (
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
              sx={{
                ...styles.advanceSearchIcon,
                width: 35,
                height: 35,
                marginX: 0.5
              }}>
              <Badge
                variant='dot'
                color='primary'
                invisible={!active}
                sx={{ '& .MuiBadge-badge': { backgroundColor: 'primary.600' } }}>
                <AdvanceSearchIcon />
              </Badge>
            </IconButton>
          )}
          {type === 'iconButton' && (
            <IconButton sx={styles.advanceSearchIcon} onClick={() => setOpen(true)}>
              <AdvanceSearchIcon />
            </IconButton>
          )}
          {type === 'text' && (
            <Button
              variant='text'
              onClick={() => setOpen(true)}
              sx={{
                paddingLeft: '4px',
                fontSize: 14,
                textTransform: 'capitalize',
                color: 'primary.700',
                fontWeight: 700
              }}>
              Advanced Search
            </Button>
          )}
        </span>
      </Tooltip>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='md' sx={styles.dialogBox}>
        <DialogTitle p='0 !important' mb='16px'>
          <Box>
            {resultsState.advanceSearchAlertMsg && (
              <AdvanceSearchAlert handleAlertClose={handleAlertClose} />
            )}
          </Box>
          <Typography
            textAlign='center'
            fontWeight={700}
            fontSize={20}
            sx={{ color: 'gray.800', p: 0 }}>
            Advanced Search
          </Typography>
          <Divider sx={styles.divider} />
          <IconButton title='Close' onClick={onClose} sx={styles.advanceSearchCloseIcon}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          {disabled && <AdvancedSearchLoading />}
          <Box sx={styles.query}>
            {getQueryText().length !== 0 ? (
              <Typography sx={{ ...styles.queryTitle, color: 'grey.800', fontWeight: 700 }}>
                {getQueryText().map((text: string, index: number) => {
                  if (text.includes('Label Sections:[')) {
                    // eslint-disable-next-line react/no-array-index-key
                    return <AdvanceSearchTitleText key={index} title={text as string} />;
                  }
                  // eslint-disable-next-line react/no-array-index-key
                  return <span key={index}>&nbsp;{text}&nbsp;</span>;
                })}
              </Typography>
            ) : (
              <Typography sx={{ ...styles.queryTitle, color: 'grey.500', fontWeight: 400 }}>
                Please make a category selection and enter your search term
              </Typography>
            )}
          </Box>
          <Box display='flex' height='400px' alignItems='flex-start' flexDirection='row'>
            <Box display='flex' flexDirection='column' sx={styles.scopeBox}>
              <FormControl>
                <FormLabel
                  id='scope-radio-buttons-group'
                  sx={{
                    ...styles.scopeRadio,
                    color: 'grey.600',
                    '&.Mui-focused': { color: 'grey.600' }
                  }}>
                  Scope
                </FormLabel>
                <RadioGroup value={scope} onChange={handleChange}>
                  {ADVANCED_SEARCH_SCOPE_LIST.map((item: any) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      sx={{ ...styles.scopeRadio, color: 'grey.800' }}
                      control={<Radio sx={styles.scopeRadioButton} />}
                      label={item.label}
                      disabled={item.enabled.length !== 0 ? !item.enabled.includes(region) : false}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider sx={styles.dividerLine} />
              <FormControl>
                <FormLabel
                  id='source-radio-buttons-group'
                  sx={{
                    ...styles.scopeRadio,
                    mt: 0,
                    color: 'grey.600',
                    '&.Mui-focused': { color: 'grey.600' }
                  }}>
                  Region
                </FormLabel>
                <RadioGroup
                  value={region}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleRegionChange(event);
                  }}>
                  {ADVANCED_SEARCH_REGION_LIST.map((item: any) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      sx={{ ...styles.scopeRadio, color: 'grey.800' }}
                      control={<Radio sx={styles.scopeRadioButton} />}
                      label={item.label}
                      disabled={item.enabled.length !== 0 ? !item.enabled.includes(scope) : false}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box display='flex' flexDirection='column'>
              {advancedSearchTerm.map((row, index) => (
                <Box borderRadius={2} px='16px' position='relative' key={row.id}>
                  <AdvancedSearchItems
                    id={row.id}
                    isHighlighted={highlightRowIds.includes(index)}
                    setHighlightRowIds={setHighlightRowIds}
                    category={row.category}
                    subCategoryList={row.subCategory}
                    searchTerm={row.searchTerm}
                    condition={row.condition}
                    exactMatch={row.exactMatch}
                    categoryOptions={categoryOptions}
                    setItem={setAdvancedSearchTerm}
                    disabled={disabled}
                    isLastItem={index === advancedSearchTerm.length - 1}
                    advancedSearch={advancedSearch}
                    setAdvancedSearch={setAdvancedSearch}
                    compareCondition={row.compareCondition}
                    rowIndex={index}
                    rowsLength={advancedSearchTerm.length}
                    rows={advancedSearchTerm}
                    source={source}
                  />
                </Box>
              ))}

              <Grow in={advancedSearchTerm.length < 5}>
                <Stack direction='row' justifyContent='center' width='100%'>
                  <Tooltip title='Add new row'>
                    <IconButton
                      disabled={disabled || disableAddCheck}
                      color='primary'
                      sx={{
                        ...styles.addNewRowButton
                      }}
                      onClick={addNewRow}>
                      <AddCircleOutlined sx={{ fontSize: 32 }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grow>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.advanceSearchActions}>
          <FormControlLabel
            disabled={disabled}
            value={advancedSearch.useSynonyms}
            onChange={(e: any) => {
              setAdvancedSearch({ ...advancedSearch, useSynonyms: e.target?.checked });
            }}
            control={
              <Switch
                defaultChecked={advancedSearch.useSynonyms}
                value={advancedSearch.useSynonyms}
              />
            }
            label='Use Synonymns'
            labelPlacement='start'
            sx={{
              mr: '8px',
              color: 'primary.800'
            }}
          />
          <Button
            size='small'
            disabled={disabled || disableAddCheck}
            startIcon={<SearchIcon style={{ fontSize: 12 }} />}
            sx={styles.searchButton}
            onClick={handleSubmit}>
            Advanced Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(AdvancedSearch);
