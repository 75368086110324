import makeRequest, {
  makeBlobRequestWithCancel,
  makeStreamRequestWithCancel
} from '../../../api/client';

export const getApplicationsByLabelSection = (payload: any) => {
  return makeRequest('/collated-summary/labeled_applications', 'POST', payload);
};

export const downloadCollatedSummary = (payload: any, cancelToken: any) => {
  return makeBlobRequestWithCancel('/collated-summary/download', 'POST', payload, {}, cancelToken);
};

export const downloadCollatedSummaryProgress = ({
  jobID,
  cancelToken,
  onDownloadProgress
}: {
  jobID: string;
  cancelToken: any;
  onDownloadProgress: any;
}) => {
  return makeStreamRequestWithCancel(
    `/collated-summary/progress/${jobID}`,
    'GET',
    {},
    {},
    cancelToken,
    onDownloadProgress
  );
};
