/* eslint-disable no-unused-vars */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Papa from 'papaparse';

import { isEqual } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {
  getAdvancedSearch,
  getAriaSearchWithSearchId,
  getGenericSearch,
  getGenericSearchWithSearchId,
  getGenericSearchCt,
  getCtFilterOptions,
  getCtFilterOptionsWithSearchId,
  getAdvancedSearchCt,
  getAdvancedSearchCtSaveDisabled,
  getAdvancedSearchCtFilterOptions
} from '../../../api/pages/ResultsPage';

import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';

import { decodeBase64ToObject, encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import { formatDocumentResults } from '../utils/documentResultsUtils';
import useQuery from '../../../helpers/customHooks/queryParameter';
import RESULT_VIEW_TYPES from '../components/constants';
import AuthContext from '../../../store/Auth/AuthProvider';
import entityHandler from '../utils/entityHandler';
import { prepareAPIPayload } from '../utils/searchUtils';
import getCtSource from '../utils/getCtSource';
import { CT_COLUMNS, EU_CT_COLUMNS } from '../../ResultsPage/utils/columnDefinitions';
import { DEFAULT_CT_PAGINATION } from '../constants';

const useFetchResults = () => {
  const { payload }: any = useParams();
  const queryParam = useQuery();
  const history = useHistory();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { currentUser } = useContext(AuthContext);

  const [data, setData] = useState('');
  const [error, setError] = useState(null);
  const localFilters = useRef<any>({});
  const isFirstFetch = useRef(true);
  const localViewType = useRef('');
  const localSearchPayload = useRef<any>('');
  const [openEntityPopup, setOpenEntityPopup] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const localDataGridFilters = useRef<any>({});

  /**
   * Handles a click event to performed on one of the entities shown on entity selection popup.
   * Decodes the payload from URL parameters, sends a search request,
   * and updates the application's state with the search results and view type.
   *
   * @param {string} entityText - The term to be searched.
   */
  const entityClick = async (entityText: string) => {
    resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: true });
    // Closes the entity popup
    setOpenEntityPopup(false);

    const decodedPayload: any = decodeBase64ToObject(payload);
    decodedPayload.entity_text = entityText;
    decodedPayload.query = decodedPayload.search_term;
    decodedPayload.view_type = RESULT_VIEW_TYPES.APPLICATION;
    history.push(`/search/${encodeObjectToBase64(decodedPayload)}`);
  };

  const getSearchIds = (resultBody: any, urlSearchId: any) => {
    let documentSearchId = urlSearchId
      ? urlSearchId.documentSearchId
      : resultsState?.searchIds?.documentSearchId;
    let applicationSearchId = urlSearchId
      ? urlSearchId.applicationSearchId
      : resultsState?.searchIds?.applicationSearchId;
    let ctSearchId = urlSearchId ? urlSearchId.ctSearchId : resultsState?.searchIds?.ctSearchId;
    const {
      search_id: searchId = '',
      view_type_metadata: viewTypeData = null,
      view_type: viewType = ''
    } = resultBody || {};
    if (viewTypeData?.search_id) {
      if (viewType === RESULT_VIEW_TYPES.APPLICATION) {
        applicationSearchId = viewTypeData.search_id;
      }
      if (viewType === RESULT_VIEW_TYPES.CT) {
        ctSearchId = viewTypeData.search_id;
      }
      if (viewType === RESULT_VIEW_TYPES.DOCUMENT) {
        documentSearchId = searchId;
      }
    } else {
      if (viewType === RESULT_VIEW_TYPES.APPLICATION) {
        applicationSearchId = searchId;
      }
      if (viewType === RESULT_VIEW_TYPES.CT) {
        ctSearchId = searchId;
      }
      if (viewType === RESULT_VIEW_TYPES.DOCUMENT) {
        documentSearchId = searchId;
      }
    }
    return {
      documentSearchId,
      applicationSearchId,
      ctSearchId
    };
  };

  const getSearchIdForViewType = (
    view: string,
    documentSearchId: string = '',
    applicationSearchId: string = '',
    ctSearchId: string = ''
  ) => {
    switch (view) {
      case RESULT_VIEW_TYPES.DOCUMENT:
        return documentSearchId;
      case RESULT_VIEW_TYPES.APPLICATION:
        return applicationSearchId;
      case RESULT_VIEW_TYPES.CT:
        return ctSearchId;
      default:
        return false;
    }
  };

  const getViewType = (viewType: string) => {
    let newViewType: any = '';
    if (resultsState.isAriaOpened) {
      return RESULT_VIEW_TYPES.DOCUMENT;
    }
    if (queryParam.get('tab')) {
      newViewType = queryParam.get('tab');
    } else if (viewType) {
      newViewType = viewType;
    } else if (resultsState?.viewType) {
      newViewType = resultsState?.viewType;
    }
    return newViewType;
  };

  const getCurrentViewType = useMemo(() => {
    return resultsState.isAriaOpened ? RESULT_VIEW_TYPES.DOCUMENT : resultsState?.viewType;
  }, [resultsState?.viewType, resultsState.isAriaOpened]);

  const getResultFilters = (resultBody: any) => {
    let resultFilters = resultBody?.filters ?? {};
    if (resultBody?.view_type === RESULT_VIEW_TYPES.DOCUMENT) {
      resultFilters = resultBody?.result?.filters?.checked_filter ?? {};
    }
    if (
      !resultFilters &&
      'view_type_metadata' in resultBody &&
      resultBody.view_type_metadata?.search_id
    ) {
      resultFilters = localFilters.current;
    }

    if (!isEqual(localFilters.current, resultFilters)) {
      if (!isEmpty(resultFilters)) {
        localFilters.current = resultFilters;
      } else {
        resultFilters = localFilters.current;
      }
    }
    return resultFilters;
  };

  const isCtTab = (sources: any) => {
    if (sources?.ct) {
      return true;
    }

    if (!sources?.eu) {
      return false;
    }

    const { eu } = sources;
    // check if eu has euctr to call filter and stats api
    if (eu?.length !== 0 && eu.includes('euctr')) {
      return true;
    }

    return false;
  };

  const getCtPageLimit = (isPreviousFiltersSame: boolean) => {
    return isPreviousFiltersSame
      ? resultsState?.ctPagination?.pageSize ?? DEFAULT_CT_PAGINATION.limit
      : DEFAULT_CT_PAGINATION.limit;
  };

  const getCtPageOffset = (isPreviousFiltersSame: boolean) => {
    return isPreviousFiltersSame
      ? (resultsState?.ctPagination?.pageSize ?? DEFAULT_CT_PAGINATION.limit) *
          (resultsState?.ctPagination?.page ?? DEFAULT_CT_PAGINATION.offset)
      : DEFAULT_CT_PAGINATION.offset;
  };

  const updateCtStatsState = (ctStats: any) => {
    const stats = {
      totalTrials: ctStats?.total ?? ctStats?.totalTrials ?? 0,
      uniqueInterventions: ctStats?.total_unique_interventions ?? ctStats?.uniqueInterventions ?? 0,
      totalDocuments: ctStats?.document_count ?? ctStats?.totalDocuments ?? 0,
      totalPages: ctStats?.document_total_page_count ?? ctStats?.totalPages ?? 0,
      totalApplications: ctStats?.totalRows ?? 0
    };
    resultsDispatch({
      type: ResultActions.SET_CT_STATS,
      value: stats
    });
  };

  const getCtFiltersBySearchIdAndUpdateStatsAndFilters = async (
    ctSearchId: string,
    totalRows: number
  ) => {
    const ctSearchIdFilters = await getCtFilterOptionsWithSearchId(ctSearchId);

    const ctStats = ctSearchIdFilters?.data?.body?.stats;

    if (ctSearchIdFilters?.status === 200 && ctStats) {
      const newStats = {
        ...ctStats,
        totalRows
      };
      updateCtStatsState(newStats);
    }

    if (ctSearchIdFilters?.status === 200 && ctSearchIdFilters?.data?.body?.filters) {
      resultsDispatch({
        type: ResultActions.SET_CT_FILTERS,
        value: ctSearchIdFilters?.data?.body?.filters
      });
    }
  };

  const getCtFiltersAndUpdateStatsAndFilter = async (
    API_PAYLOAD: any,
    totalRows: number,
    searchType: any
  ) => {
    const ctFilterOptionFunction =
      searchType === 'advanced' ? getAdvancedSearchCtFilterOptions : getCtFilterOptions;

    const ctFilterOptions = await ctFilterOptionFunction(API_PAYLOAD);
    const ctStats = ctFilterOptions?.data?.body?.stats;

    if (ctFilterOptions?.status === 200 && ctStats) {
      const newStats = {
        ...ctStats,
        totalRows
      };
      updateCtStatsState(newStats);
    }

    if (ctFilterOptions?.status === 200 && ctFilterOptions?.data?.body?.filters) {
      resultsDispatch({
        type: ResultActions.SET_CT_FILTERS,
        value: ctFilterOptions?.data?.body?.filters
      });
    }
  };

  const fetchData = async (isPreviousFiltersSame: boolean = false) => {
    try {
      resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: true });

      const searchPayload: any = decodeBase64ToObject(payload);
      const {
        source: sources,
        search_type: searchType = '',
        document_search_id: documentSearchId = '',
        application_search_id: applicationSearchId = '',
        ct_search_id: ctSearchId = '', // eslint-disable-next-line camelcase
        view_type: payloadViewType,
        entities
      } = searchPayload;

      const viewType = getViewType(payloadViewType);

      const API_PAYLOAD = prepareAPIPayload(searchPayload, viewType, resultsState, currentUser);
      let res = null;

      const CT_PAGE_LIMIT = getCtPageLimit(isPreviousFiltersSame);
      const CT_PAGE_OFFSET = getCtPageOffset(isPreviousFiltersSame);
      // this should be executed only when user refreshed the page
      if (
        getSearchIdForViewType(viewType, documentSearchId, applicationSearchId, ctSearchId) &&
        isFirstFetch.current
      ) {
        switch (viewType) {
          case RESULT_VIEW_TYPES.DOCUMENT:
            res = await getAriaSearchWithSearchId({ searchId: documentSearchId });
            break;
          case RESULT_VIEW_TYPES.APPLICATION:
            res = await getGenericSearchWithSearchId({
              searchId: applicationSearchId,
              view_type: RESULT_VIEW_TYPES.APPLICATION
            });
            break;
          case RESULT_VIEW_TYPES.CT: {
            res = await getGenericSearchWithSearchId({
              searchId: ctSearchId,
              view_type: RESULT_VIEW_TYPES.CT,
              from: CT_PAGE_OFFSET,
              limit: CT_PAGE_LIMIT
            });

            const totalRows =
              res?.data?.body?.result?.ct?.total ?? res?.data?.body?.result?.euctr?.total ?? 0;

            await getCtFiltersBySearchIdAndUpdateStatsAndFilters(ctSearchId, totalRows);
            break;
          }
          default:
            break;
        }
        // storing if search by Id is executed to calculate filters from applied filters when search response in empty
        resultsDispatch({ type: ResultActions.SET_IS_SEARCH_BY_SEARCH_ID, value: true });
      } else if (searchType === 'advanced' && !isCtTab(sources)) {
        res = await getAdvancedSearch(API_PAYLOAD);
        // storing if search by Id is executed to calculate filters from applied filters when search response in empty
        resultsDispatch({ type: ResultActions.SET_IS_SEARCH_BY_SEARCH_ID, value: false });
      } else if (
        searchType === 'advanced' &&
        (viewType === RESULT_VIEW_TYPES.CT || isCtTab(sources))
      ) {
        res = await getAdvancedSearchCt(CT_PAGE_OFFSET, CT_PAGE_LIMIT, API_PAYLOAD);
        // storing if search by Id is executed to calculate filters from applied filters when search response in empty
        resultsDispatch({ type: ResultActions.SET_IS_SEARCH_BY_SEARCH_ID, value: false });

        if (!API_PAYLOAD?.category_name) {
          const viewTypeMetadata = res?.data?.body?.view_type_metadata?.category_name ?? '';

          if (viewTypeMetadata) {
            API_PAYLOAD.category_name = viewTypeMetadata;
          }
        }

        API_PAYLOAD.search_term = API_PAYLOAD?.search_term ?? API_PAYLOAD.query;
        API_PAYLOAD.view_type = RESULT_VIEW_TYPES.CT;

        const totalRows =
          res?.data?.body?.result?.ct?.total ?? res?.data?.body?.result?.euctr?.total ?? 0;
        await getCtFiltersAndUpdateStatsAndFilter(API_PAYLOAD, totalRows, searchType);
      } else if (viewType === RESULT_VIEW_TYPES.CT || isCtTab(sources)) {
        res = await getGenericSearchCt(CT_PAGE_OFFSET, CT_PAGE_LIMIT, API_PAYLOAD);

        if (!API_PAYLOAD?.category_name) {
          const viewTypeMetadata = res?.data?.body?.view_type_metadata?.category_name ?? '';

          if (viewTypeMetadata) {
            API_PAYLOAD.category_name = viewTypeMetadata;
          }
        }

        API_PAYLOAD.search_term = API_PAYLOAD?.search_term ?? API_PAYLOAD.query;
        API_PAYLOAD.view_type = res?.data?.body?.view_type ?? RESULT_VIEW_TYPES.CT;

        const totalRows =
          res?.data?.body?.result?.ct?.total ?? res?.data?.body?.result?.euctr?.total ?? 0;

        await getCtFiltersAndUpdateStatsAndFilter(API_PAYLOAD, totalRows, searchType);
      } else {
        res = await getGenericSearch(API_PAYLOAD);
        // storing if search by Id is executed to calculate filters from applied filters when search response in empty
        resultsDispatch({ type: ResultActions.SET_IS_SEARCH_BY_SEARCH_ID, value: false });
      }

      if (res?.status === 200 || res?.status === 204) {
        const currentViewType = res?.data?.body?.view_type ?? RESULT_VIEW_TYPES.APPLICATION;
        localViewType.current = currentViewType;
        const result = res?.data?.body?.result || {};
        const searchIds = getSearchIds(res?.data?.body, {
          documentSearchId,
          applicationSearchId,
          ctSearchId
        });
        const resultFilters = getResultFilters(res?.data?.body);
        const viewTypeMetadata = res?.data?.body?.view_type_metadata ?? {};
        if (isFirstFetch.current && entities) {
          if (viewTypeMetadata?.entities) {
            viewTypeMetadata.entities = [...viewTypeMetadata.entities, ...entities];
          } else {
            viewTypeMetadata.entities = entities;
          }
        }
        isFirstFetch.current = false;
        if (res?.message === 'Query Limit Exceeded') {
          resultsDispatch({ type: ResultActions.SET_ADVANCE_SEARCH_ALERT_MSG, value: true });
        }

        resultsDispatch({ type: ResultActions.SET_FILTERS, value: resultFilters });
        resultsDispatch({ type: ResultActions.SET_DECRYPTED_SOURCE, value: sources });
        resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: API_PAYLOAD });
        resultsDispatch({ type: ResultActions.SET_VIEW_TYPE, value: currentViewType });
        resultsDispatch({
          type: ResultActions.SET_SEARCH_IDS,
          value: searchIds
        });
        resultsDispatch({
          type: ResultActions.SET_IS_FAVORITE,
          value:
            res?.data?.body?.view_type_metadata?.isFavorite ?? res?.data?.body?.isFavorite ?? false
        });
        resultsDispatch({
          type: ResultActions.SET_IS_SUBSCRIBED,
          value:
            res?.data?.body?.view_type_metadata?.isSubscribed ??
            res?.data?.body?.isSubscribed ??
            false
        });
        resultsDispatch({
          type: ResultActions.SET_IN_PROJECTS,
          value:
            res?.data?.body?.view_type_metadata?.inProjects ?? res?.data?.body?.inProjects ?? []
        });
        resultsDispatch({
          type: ResultActions.SET_ENTITY_LIST,
          value: viewTypeMetadata
        });

        const newPayload = {
          ...searchPayload,
          document_search_id: searchIds?.documentSearchId,
          application_search_id: searchIds?.applicationSearchId,
          ct_search_id: searchIds?.ctSearchId,
          view_type: currentViewType,
          entity_category: viewTypeMetadata?.category_name
        };
        if (currentViewType === 'entity_popup') {
          newPayload.entities = res?.data?.body?.entities;
        }
        const encodedPayload: any = encodeObjectToBase64(newPayload);
        localSearchPayload.current = encodedPayload;
        const previousEncodedPayload = window.location.pathname.split('/search/')[1];
        if (previousEncodedPayload !== encodedPayload) {
          const searchParams = window.location.search;
          const newPath = window.location.pathname.replace(previousEncodedPayload, encodedPayload);
          const newUrl = `${newPath}${searchParams}`;
          history.push(newUrl);
        }

        resultsDispatch({
          type: ResultActions.SET_SEARCH_SUGGESTIONS,
          value: res?.data?.body?.alternate_results ?? []
        });

        switch (currentViewType) {
          case RESULT_VIEW_TYPES.DOCUMENT:
            resultsDispatch({
              type: ResultActions.SET_DOCUMENT_RESULTS,
              value: formatDocumentResults(result)
            });
            break;
          case RESULT_VIEW_TYPES.APPLICATION:
            resultsDispatch({
              type: ResultActions.SET_APPLICATION_RESULTS,
              value: result
            });
            break;
          case RESULT_VIEW_TYPES.CT:
            // eslint-disable-next-line no-case-declarations
            const ctSource = getCtSource(sources);
            resultsDispatch({
              type: ResultActions.SET_CT_RESULTS,
              value: ctSource === 'usnlm' ? result?.ct : result?.euctr
            });
            break;
          case 'entity_popup': {
            setOpenEntityPopup(true);
            const entitiesVal = res?.data?.body?.entities;
            const currentEntityList = entityHandler(entitiesVal);
            setEntityList(currentEntityList);
            break;
          }

          default:
            break;
        }
        // clear states to avoid showing old data
        resultsDispatch({ type: ResultActions.SET_SELECTED_ARIA_RESPONSE, value: {} });
        resultsDispatch({ type: ResultActions.SET_TOP_10_SUMMARY, value: [] });
        resultsDispatch({ type: ResultActions.SET_SHOW_TOP_10_SUMMARY, value: false });
        resultsDispatch({ type: ResultActions.SET_RIA_RESPONSE_TITLE, value: 'Top Result' });
        resultsDispatch({
          type: ResultActions.SET_SELECTED_DOCUMENT,
          value: null
        });
      }
      setData('Sample data');
    } catch (err: any) {
      setError(err);
    } finally {
      resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: false });
      if (resultsState?.isSourceChangedWithFilters) {
        resultsDispatch({ type: ResultActions.SET_IS_SOURCE_CHANGED_WITH_FILTERS, value: false });
      }
      if (resultsState?.isNewAdvanceSearch) {
        resultsDispatch({ type: ResultActions.SET_IS_NEW_ADVANCE_SEARCH, value: false });
      }
      // once the search is executed with new search term without any filters from results page
      // we should reset the value of global state isNewSearchTerm to false
      if (resultsState?.isNewSearchTerm) {
        resultsDispatch({ type: ResultActions.SET_IS_NEW_SEARCH_TERM, value: false });
      }
    }
  };

  const fetchCtData = async () => {
    try {
      resultsDispatch({ type: ResultActions.SET_CT_IS_LOADING, value: true });

      const searchPayload: any = decodeBase64ToObject(payload);
      const {
        document_search_id: documentSearchId = '',
        application_search_id: applicationSearchId = '',
        ct_search_id: ctSearchId = '', // eslint-disable-next-line camelcase
        view_type: payloadViewType,
        search_type: searchType = ''
      } = searchPayload;

      const viewType = getViewType(payloadViewType);
      const API_PAYLOAD = prepareAPIPayload(searchPayload, viewType, resultsState, currentUser);
      let res = null;

      if (!isEmpty(resultsState?.ctSortBy)) {
        API_PAYLOAD.sort = resultsState?.ctSortBy;
      }

      const CT_PAGE_LIMIT = resultsState?.ctPagination?.pageSize ?? DEFAULT_CT_PAGINATION.limit;
      const CT_PAGE_OFFSET =
        (resultsState?.ctPagination?.pageSize ?? DEFAULT_CT_PAGINATION.limit) *
        (resultsState?.ctPagination?.page ?? DEFAULT_CT_PAGINATION.offset);

      if (
        getSearchIdForViewType(viewType, documentSearchId, applicationSearchId, ctSearchId) &&
        isFirstFetch.current
      ) {
        res = await getGenericSearchWithSearchId({
          searchId: ctSearchId,
          view_type: RESULT_VIEW_TYPES.CT,
          from: CT_PAGE_OFFSET,
          limit: CT_PAGE_LIMIT
        });

        const totalRows =
          res?.data?.body?.result?.ct?.total ?? res?.data?.body?.result?.euctr?.total ?? 0;

        if (!isEqual(localFilters.current, resultsState?.filters)) {
          const ctSearchIdFilters = await getCtFilterOptionsWithSearchId(ctSearchId);

          const ctStats = ctSearchIdFilters?.data?.body?.stats;

          if (ctSearchIdFilters?.status === 200 && ctStats) {
            const newStats = {
              ...ctStats,
              totalRows:
                res?.data?.body?.result?.ct?.total ?? res?.data?.body?.result?.euctr?.total ?? 0
            };
            updateCtStatsState(newStats);
          } else {
            updateCtStatsState({ ...resultsState?.ctStats, totalRows });
          }

          if (ctSearchIdFilters?.status === 200 && ctSearchIdFilters?.data?.body?.filters) {
            resultsDispatch({
              type: ResultActions.SET_CT_FILTERS,
              value: ctSearchIdFilters?.data?.body?.filters
            });
          }
        }
      } else {
        const searchCtFn =
          searchType === 'advanced' ? getAdvancedSearchCtSaveDisabled : getGenericSearchCt;
        res = await searchCtFn(CT_PAGE_OFFSET, CT_PAGE_LIMIT, API_PAYLOAD);
        API_PAYLOAD.search_term = API_PAYLOAD?.search_term ?? API_PAYLOAD.query;
        API_PAYLOAD.view_type = RESULT_VIEW_TYPES.CT;

        const totalRows =
          res?.data?.body?.result?.ct?.total ?? res?.data?.body?.result?.euctr?.total ?? 0;

        if (!isEqual(localFilters.current, resultsState?.filters)) {
          const filterOptionsFn =
            searchType === 'advanced' ? getAdvancedSearchCtFilterOptions : getCtFilterOptions;
          const ctFilterOptions = await filterOptionsFn(API_PAYLOAD);
          const ctStats = ctFilterOptions?.data?.body?.stats;

          if (ctFilterOptions?.status === 200 && ctStats) {
            const newStats = {
              ...ctStats,
              totalRows
            };
            updateCtStatsState(newStats);
          } else {
            updateCtStatsState({ ...resultsState?.ctStats, totalRows });
          }

          if (ctFilterOptions?.status === 200 && ctFilterOptions?.data?.body?.filters) {
            resultsDispatch({
              type: ResultActions.SET_CT_FILTERS,
              value: ctFilterOptions?.data?.body?.filters
            });
          }
        } else {
          updateCtStatsState({ ...resultsState?.ctStats, totalRows });
        }
      }

      const result = res?.data?.body?.result || {};
      resultsDispatch({
        type: ResultActions.SET_CT_RESULTS,
        value: result?.ct ?? result?.euctr
      });
    } catch (err: any) {
      setError(err);
    } finally {
      resultsDispatch({ type: ResultActions.SET_CT_IS_LOADING, value: false });
    }
  };

  const getOperatorBasedSpecialQuery = (operator: string, query: string) => {
    if (operator === 'contains') {
      // eslint-disable-next-line no-useless-escape
      return `\"${query}\"`;
    }

    if (operator === 'equals') {
      return `\`${query}\``;
    }

    return query;
  };

  const fetchDataGridAppliedCtData = async () => {
    const isPreviousDataGridFiltersSame = isEqual(
      localDataGridFilters.current,
      resultsState?.ctDataGridFilters
    );

    if (isPreviousDataGridFiltersSame && !resultsState?.ctSortBy) {
      return;
    }

    localDataGridFilters.current = resultsState?.ctDataGridFilters;

    try {
      resultsDispatch({ type: ResultActions.SET_CT_IS_LOADING, value: true });

      const searchPayload: any = decodeBase64ToObject(payload);
      const {
        search_term: searchTerm,
        entity_category: entityCategory,
        view_type: payloadViewType
      } = searchPayload;

      const viewType = getViewType(payloadViewType);
      const API_PAYLOAD = prepareAPIPayload(searchPayload, viewType, resultsState, currentUser);

      API_PAYLOAD.approval_date = { start_date: '', end_date: '' };
      API_PAYLOAD.use_synonyms = true;
      API_PAYLOAD.approvalPathway = {};
      API_PAYLOAD.searchType = 'advanced';

      if (resultsState?.ctSortBy) {
        API_PAYLOAD.sort = resultsState?.ctSortBy;
      }

      API_PAYLOAD.query =
        searchPayload?.search_type === 'advanced'
          ? searchTerm
          : `(${entityCategory}: ${searchTerm})`;

      const filterFields = resultsState?.ctDataGridFilters?.items || [];

      let logicalOperator = 'AND';

      let dataGridFiltersQuery = '';

      filterFields.forEach((filter: any, index: number) => {
        if (filter?.value?.query?.length > 0) {
          dataGridFiltersQuery += ` ${logicalOperator} (${
            filter.field
          }: ${getOperatorBasedSpecialQuery(filter.operator, filter.value.query)})`;

          logicalOperator = filter.value.operator.toUpperCase();
        }
      });

      if (isEmpty(dataGridFiltersQuery)) {
        fetchCtData();
        return;
      }

      API_PAYLOAD.query += dataGridFiltersQuery;

      let res = null;

      const CT_PAGE_LIMIT = getCtPageLimit(isPreviousDataGridFiltersSame);
      const CT_PAGE_OFFSET = getCtPageOffset(isPreviousDataGridFiltersSame);

      res = await getAdvancedSearchCtSaveDisabled(CT_PAGE_OFFSET, CT_PAGE_LIMIT, API_PAYLOAD);

      const result = res?.data?.body?.result ?? {};
      resultsDispatch({
        type: ResultActions.SET_CT_RESULTS,
        value: result?.ct ?? result?.euctr ?? {}
      });

      const newStats = {
        ...resultsState?.ctStats,
        totalRows: res?.data?.body?.result?.ct?.total ?? res?.data?.body?.result?.euctr?.total ?? 0
      };

      updateCtStatsState(newStats);
    } catch (err: any) {
      setError(err);
    } finally {
      resultsDispatch({ type: ResultActions.SET_CT_IS_LOADING, value: false });
    }
  };

  const downloadCtDatagrid = async () => {
    try {
      if (!resultsState?.ctExport?.isOn || !resultsState?.ctExport?.type) {
        return;
      }

      const SOURCE_COLUMNS =
        resultsState?.ctExport?.source === 'usnlm' ? CT_COLUMNS : EU_CT_COLUMNS;

      const SELECTED_COLUMNS =
        resultsState?.ctExport?.columns?.length > 0
          ? resultsState?.ctExport?.columns
          : SOURCE_COLUMNS;

      resultsDispatch({ type: ResultActions.SET_CT_IS_LOADING, value: true });

      const searchPayload: any = decodeBase64ToObject(payload);
      const { ct_search_id: ctSearchId = '', search_term: searchTerm } = searchPayload;

      const res = await getGenericSearchWithSearchId({
        searchId: ctSearchId,
        view_type: RESULT_VIEW_TYPES.CT
      });

      const json =
        res?.data?.body?.result?.ct?.results ?? res?.data?.body?.result?.euctr?.results ?? null;

      if (!json) {
        return;
      }

      const urlColumns: any = [];
      const exportData = json.map((item: any, index: number) => {
        const newItem: any = {};
        let exportableColumnIndex = 0;
        SELECTED_COLUMNS.forEach((column: any) => {
          if (column?.headerName?.length > 0 && !column?.disableExport) {
            const value =
              'valueGetter' in column
                ? column.valueGetter({ row: item })
                : item[column.field] ?? '';

            newItem[column.headerName] = value;

            if (column.isLink && index === 0) {
              urlColumns.push({ index: exportableColumnIndex, columnHeader: column?.headerName });
            }
            exportableColumnIndex += 1;
          }
        });
        return newItem;
      });

      if (resultsState?.ctExport?.type === 'xlsx') {
        const XLSX = await import('xlsx');
        const ws = XLSX.utils.json_to_sheet(exportData);

        // Go through all the url columns and update the formula for that cell to open it in browser.
        urlColumns.forEach((column: any) => {
          const columnNames = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          const urlColumn = columnNames[column.index];
          exportData.forEach((row: any, rowIndex: number) => {
            const cellAddress = `${urlColumn}${rowIndex + 2}`;
            const url = row[column.columnHeader];
            if (url) {
              ws[cellAddress] = {
                f: `HYPERLINK("${url}", "${url}")`,
                v: url
              };
            }
          });
        });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `ria_search_ct_${searchTerm}.xlsx`);
        return;
      }

      const csv = Papa.unparse(exportData);

      // Create a Blob from the CSV string
      const blob = new Blob([csv], { type: 'text/csv' });

      // Create a download link and click it
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = `ria_search_ct_${searchTerm}.csv`;
      a.click();
    } catch (err: any) {
      setError(err);
    } finally {
      resultsDispatch({ type: ResultActions.SET_CT_IS_LOADING, value: false });
      resultsDispatch({
        type: ResultActions.SET_CT_EXPORT,
        value: { type: '', isOn: false, source: '', columns: [] }
      });
    }
  };

  useEffect(() => {
    if (
      (!isEqual(localFilters.current, resultsState?.filters) && !isFirstFetch.current) ||
      (isEmpty(localFilters.current) && isEmpty(resultsState?.filters) && isFirstFetch.current)
    ) {
      const isPreviousFiltersSame = isEqual(localFilters.current, resultsState?.filters);
      localFilters.current = resultsState?.filters;
      fetchData(isPreviousFiltersSame);
    }
  }, [resultsState?.filters]);

  useEffect(() => {
    if (isFirstFetch.current || resultsState?.viewType !== RESULT_VIEW_TYPES.CT) {
      return;
    }

    if (isEmpty(resultsState?.ctDataGridFilters)) {
      fetchCtData();
    } else {
      fetchDataGridAppliedCtData();
    }
  }, [resultsState?.ctPagination, resultsState?.ctSortBy, resultsState?.ctDataGridFilters]);

  useEffect(() => {
    if (resultsState?.viewType === RESULT_VIEW_TYPES.CT && resultsState?.ctExport?.isOn) {
      downloadCtDatagrid();
    }
  }, [resultsState?.ctExport]);

  const shouldFetchData = () => {
    const {
      source: urlSources,
      search_term: urlSearchTerm,
      search_type: urlSearchType = '',
      approval_date: urlApprovalDate,
      use_synonyms: urlUseSynonyms,
      approvalPathway: urlApprovalPathway,
      entities: urlEntities,
      query: urlQuery,
      entity_text: urlEntityTextValue,
      entity_category: urlEntityCategory
    }: any = decodeBase64ToObject(payload);
    const {
      source: localSources,
      search_type: localSearchType = '',
      search_term: localSearchTerm,
      approval_date: localApprovalDate,
      use_synonyms: localUseSynonyms,
      approvalPathway: localApprovalPathway,
      entities: localEntities,
      query: localQuery,
      entity_text: localEntityTextValue,
      entity_category: localEntityCategory
    }: any = decodeBase64ToObject(localSearchPayload.current);
    return (
      (!isEqual(localSources, urlSources) ||
        !isEqual(localSearchTerm, urlSearchTerm) ||
        !isEqual(localSearchType, urlSearchType) ||
        !isEqual(localApprovalDate, urlApprovalDate) ||
        !isEqual(localUseSynonyms, urlUseSynonyms) ||
        !isEqual(localApprovalPathway, urlApprovalPathway) ||
        !isEqual(localEntities, urlEntities) ||
        !isEqual(localQuery, urlQuery) ||
        !isEqual(localEntityTextValue, urlEntityTextValue) ||
        !isEqual(localEntityCategory, urlEntityCategory)) &&
      // this condition need to be added in order to prevent search api to call twice when filter is applied and changing the source
      !resultsState?.isSourceChangedWithFilters &&
      // this condition need to be added in order to prevent search api to call twice when new advance search is done from result page with synonym filter applied
      !resultsState?.isNewAdvanceSearch &&
      // this condition need to be added in order to prevent search api to call twice when new search term is searched from result page
      !resultsState?.isNewSearchTerm
    );
  };

  useEffect(() => {
    if (!isFirstFetch.current && !isEmpty(localSearchPayload.current)) {
      if (shouldFetchData()) {
        fetchData();
      }
    }
  }, [payload]);

  useEffect(() => {
    if (!isFirstFetch.current && queryParam.get('tab')) {
      isFirstFetch.current = true;
      localFilters.current = {};
      resultsDispatch({ type: ResultActions.SET_FILTERS, value: {} });
    }
  }, [queryParam]);

  return {
    data,
    error,
    viewType: getCurrentViewType,
    fetchData,
    openEntityPopup,
    setOpenEntityPopup,
    entityList,
    entityClick,
    isFirstFetch
  };
};

export default useFetchResults;
