import React, { useCallback, useContext, useState } from 'react';

import { Box, Button, Fab, Badge, Tooltip } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';

import { Auth } from 'aws-amplify';
import { v4 } from 'uuid';
import styles from '../styles/LabelSectionSelection.styles';

import ResultsActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import GlobalStore from '../../../store';
import Actions from '../../../store/actions';
import { downloadCollatedSummary, downloadCollatedSummaryProgress } from '../data/collatedSummary';
import CloseIcon from '../../../assets/svgs/Icons/CloseIcon';
import { DownloadIcon, LeftArrowIcon } from '../../../assets/svgs/Icons';
import downloadLabelFile from '../utils/downloadLabelFile';
import { Progress } from '../../../components/Comparison/interface';
import DownloadLoadingModal from '../../../components/Comparison/DownloadModal';

const DownloadLabelSection = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { dispatch } = useContext<any>(GlobalStore);
  const [cancel, setCancel] = React.useState<CancelTokenSource | null>(null);
  const [downloadProgress, setDownloadProgress] = useState<Progress>({
    message: 'Starting Compilation',
    progress: 0
  });
  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);

  const exitLabelSection = () => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: false });
    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES,
      value: [
        {
          id: v4(),
          source: '',
          sections: ''
        }
      ]
    });
  };

  const handleDownloadProgressSSE = (progressEvent: any) => {
    const { currentTarget } = progressEvent;
    const data = currentTarget.response;
    const lines = data
      .trim()
      .split('\n')
      .filter((line: string) => line !== '');
    lines.forEach((line: string) => {
      if (line.startsWith('data: ')) {
        try {
          const newMessage = JSON.parse(line.replace('data: ', ''));
          if (!('progress' in newMessage) || !('message' in newMessage)) return;
          if (
            downloadProgress.progress > newMessage.progress ||
            (downloadProgress.progress === newMessage.progress &&
              downloadProgress.message === newMessage.message)
          )
            return;
          setDownloadProgress(newMessage);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    });
  };

  const handleCancelCall = useCallback(async () => {
    if (cancel) {
      cancel.cancel('Operation canceled by the user.');
      setCancel(null);
    }
  }, [cancel]);

  const handleDownloadCollatedSummary = async () => {
    const user = await Auth.currentUserInfo();
    const username = user.attributes['custom:user'] ?? '';
    const cancelToken = axios.CancelToken.source();
    const downloadType = resultsState?.collatedSummaryDownloadType ?? 'html';
    setCancel(cancelToken);

    try {
      if (resultsState?.collatedSummarySelectedApplications?.length < 1) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Please select at least 1 label', duration: 3000 }
        });
        return;
      }

      setDownloadError(false);
      setDownloadModalOpen(true);
      setDownloadProgress({
        message: 'Starting Compilation',
        progress: 0
      });

      let collatedApplication: any[] = [];

      const applicationNumbers = resultsState?.collatedSummarySelectedApplications?.map(
        selectedApp => selectedApp.labelSelectionIdentifier
      );

      collatedApplication = resultsState?.collatedSummaryApplications?.filter((appl: any) => {
        return applicationNumbers.includes(`${appl.source}-${appl.number}-${appl.section}`);
      });
      const jobID = v4();

      const payload = {
        username,
        download_type: downloadType,
        applications: collatedApplication,
        job_id: jobID
      };
      if (downloadType !== 'html') {
        // This is to handle the progress bar for download
        const cancelProgressToken = axios.CancelToken.source();
        downloadCollatedSummaryProgress({
          jobID,
          cancelToken: cancelProgressToken.token,
          onDownloadProgress: handleDownloadProgressSSE
        });
      }
      const res = await downloadCollatedSummary(payload, cancelToken.token);
      if (res?.status === 200) {
        downloadLabelFile(res.data, downloadType, `Latest-Label-Comparison.${downloadType}`);
        setDownloadProgress({
          message: 'Conversion Completed',
          progress: 100
        });
      } else {
        setDownloadError(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setDownloadError(true);
      if (!cancel) {
        setDownloadModalOpen(false);
      }
      setDownloadProgress({
        message: 'Download Failed',
        progress: 0
      });
    }
    setCancel(null);
  };

  const handleSectionSelection = () => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: true });
  };

  const closeDownloadModal = useCallback(() => {
    setDownloadModalOpen(false);
    setDownloadError(false);
    setDownloadProgress({
      message: 'Starting Compilation',
      progress: 0
    });
  }, []);

  return (
    <Box sx={styles.labelSectionDownloadContainer}>
      <Button type='button' sx={styles.sectionSelectionButton} onClick={handleSectionSelection}>
        <LeftArrowIcon sx={styles.leftArrowIcon} />
        Section Selection
      </Button>

      <Fab
        sx={styles.downloadButton}
        aria-label='download-label'
        onClick={handleDownloadCollatedSummary}
        variant='extended'>
        <Badge
          badgeContent={resultsState?.collatedSummarySelectedApplications?.length}
          color='error'
          showZero
          sx={styles.badge}>
          <DownloadIcon sx={styles.downloadIcon} />
        </Badge>
        <Box sx={styles.buttonText}>Download</Box>
      </Fab>

      <Button variant='outlined' type='submit' sx={styles.exitButton} onClick={exitLabelSection}>
        <Tooltip title='Exit mode'>
          <CloseIcon sx={styles.closeIcon} />
        </Tooltip>
      </Button>
      {downloadModalOpen && (
        <DownloadLoadingModal
          modalOpen={downloadModalOpen}
          closeModal={closeDownloadModal}
          progressData={downloadProgress}
          downloadError={downloadError}
          setDownloadError={setDownloadError}
          handleDownloadComparison={handleDownloadCollatedSummary}
          handleCancelCall={handleCancelCall}
        />
      )}
    </Box>
  );
};

export default DownloadLabelSection;
