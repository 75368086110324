const styles = {
  locationIcon: {
    color: 'gray.700'
  },
  sourceButton: { color: 'inherit', textAlign: 'start', paddingX: '12px', paddingY: 1 },
  navSourceButton: {
    borderRadius: 2,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'gray.300',
    height: 33,
    mr: 1
  },
  keydownIconNav: {
    stroke: 'gray.400',
    transition: 'transform 0.3s ease-in-out',
    fontSize: `12px !important`
  },
  keydownIcon: {
    fontSize: 16,
    color: 'gray.700'
  },
  keydownIconColor: {
    fontSize: 12,
    color: 'primary.700'
  },
  scopeText: {
    color: 'primary.700',
    fontWeight: 400,
    fontSize: 9,
    textTransform: 'None'
  },
  menuText: {
    color: 'gray.800',
    fontWeight: '400',
    fontSize: 20
  },
  helperText: {
    color: 'green.jungle'
  },
  option: {
    fontSize: 13,
    fontWeight: 700,
    color: 'gray.800'
  },
  optionModule: {
    fontSize: 13,
    fontWeight: 400,
    color: 'gray.800'
  },
  checkbox: {
    '&.Mui-checked': {
      color: 'primary.600'
    }
  },
  moduleListButton: { paddingLeft: 2.5, paddingRight: 1, paddingY: 0 },
  applyButton: {
    bgcolor: 'primary.650',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    border: 1,
    marginY: 1,
    marginX: 2.5,
    flex: 1,
    height: '30px',
    borderColor: 'primary.650',
    borderRadius: '16px',
    borderStyle: 'solid',
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: '12px',
    color: 'white.main',
    '&:hover': {
      bgcolor: 'primary.700'
    }
  },
  navbarDropdown: {
    width: '142px !important',
    background: 'white !important',
    zIndex: 2,
    border: '1px solid #E0E0E0',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: '40px',
    ml: '0',
    p: '8px 16px !important',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    fontSize: '13px !important',
    gap: '6px',
    '& > div': {
      width: '100%'
    }
  }
};

export default styles;
