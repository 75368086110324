import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from 'lodash';
// import SearchIcon from '../../../assets/svgs/Icons/SearchIcon';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../../Home/styles/SearchBar.styles';
import useSearchSuggestions from '../../Home/hooks/useSearchSuggestions';
import { getAriaSearchWithSearchId, getGenericSearch } from '../../../api/pages/ResultsPage';
import SearchBarAutoComplete from '../../Home/components/SearchBarAutoComplete';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import GlobalActions from '../../../store/actions';
import AuthContext from '../../../store/Auth/AuthProvider';
import DocumentsView from './DocumentsView';
import NewFilters from '../../../components/NewFilters';
import { Category } from '../../../components/NewFilters/types';
import {
  sortFilterCategoriesOptions,
  sortFilterCatgories
} from '../../../components/NewFilters/utils';
import { BOTTOM_FILTERS_ORDER } from '../../../components/NewFilters/constants';
import Loading from './Loading';
import { formatDocumentResults } from '../utils/documentResultsUtils';
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import { SourceDropdown } from '../../Home/types';

const AriaDrawerCore = ({
  applications,
  sources,
  groupId,
  modalOpen,
  setModalOpen,
  dispatch,
  docCount = undefined,
  docPageCount = undefined
}: any) => {
  const history = useHistory();
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);
  const [documentCount, setDocumentCount] = useState(0);
  const [newFilters, setNewFilters] = useState<Record<string, any>[]>([]);
  const [showDocumentsModal, setShowDocumentModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { currentUser } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedSources);

  const sourceDropDownAnchorEl: null | HTMLElement = null;
  const sourceDropDownOpen = Boolean(sourceDropDownAnchorEl);

  const calculateFilters = (globalFilters: any) => {
    let formattedAllowedFilters: any = [];
    if (globalFilters && globalFilters.length > 0) {
      formattedAllowedFilters = globalFilters?.filter((each: any) => each.show);
      formattedAllowedFilters.forEach((each: any, index: any) => {
        formattedAllowedFilters[index].options = each?.options.sort((a: any, b: any) =>
          sortFilterCategoriesOptions(a.label, b.label)
        );
        formattedAllowedFilters[index].showInBottomFilter = true;
        delete Object.assign(each, { filterType: each.filter_type }).filter_type;
      });
    }
    const sourceString = Object.entries(sources).map(
      ([key, value]: [string, any]) => `document-${key}-${value.join('-')}`
    )[0];

    const seqArray = BOTTOM_FILTERS_ORDER[sourceString] ?? [];
    formattedAllowedFilters = formattedAllowedFilters.sort((a: any, b: any) =>
      sortFilterCatgories(a.label, b.label, seqArray)
    );
    return formattedAllowedFilters;
  };

  const updateState = (res: any, API_PAYLOAD: any) => {
    try {
      const globalFilters = res?.data?.body?.result?.global_filters;
      const formattedAllowedFilters = calculateFilters(globalFilters);
      // Resetting the selected document to avoid showing the previous document
      resultsDispatch({
        type: ResultActions.SET_SELECTED_DOCUMENT,
        value: null
      });
      resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: API_PAYLOAD });
      resultsDispatch({ type: ResultActions.SET_DECRYPTED_SOURCE, value: sources });
      resultsDispatch({
        type: ResultActions.SET_TOP_10_SUMMARY,
        value: []
      });
      resultsDispatch({ type: ResultActions.SET_RIA_RESPONSE_TITLE, value: 'Top Result' });
      resultsDispatch({ type: ResultActions.SET_SHOW_TOP_10_SUMMARY, value: false });
      setNewFilters(formattedAllowedFilters);
      resultsDispatch({
        type: ResultActions.SET_DOCUMENT_RESULTS,
        value: formatDocumentResults(res?.data?.body?.result)
      });
      setShowDocumentModal(true);
      dispatch({ type: GlobalActions.SET_SHOW_NEW_FILTERS, value: true });
      resultsDispatch({
        type: ResultActions.SET_SEARCH_IDS,
        value: {
          ...resultsState.searchIds,
          ariaSearchId:
            res?.data?.body?.view_type_metadata?.search_id || res?.data?.body?.search_id || ''
        }
      });
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const fetchData = async (API_PAYLOAD: any) => {
    try {
      setIsLoading(true);

      const res = await getGenericSearch(API_PAYLOAD);
      if (res?.status === 200) {
        updateState(res, API_PAYLOAD);
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchShareSearchData = async (searchId: string, API_PAYLOAD: any) => {
    try {
      setIsLoading(true);
      const res = await getAriaSearchWithSearchId({ searchId });
      if (res?.status === 200) {
        updateState(res, API_PAYLOAD);
        setSelectedFilters(res?.data?.body?.result?.filters?.checked_filter || {});
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = async (selectedCategoryOptions: any) => {
    // add logic to call the api with this filters
    if (isEmpty(searchText)) {
      return;
    }
    const userDetails = {
      role: currentUser['custom:role'],
      userid: currentUser.sub,
      email: '',
      organization: currentUser['custom:organization'],
      tier: currentUser['custom:license']
    };
    const ariaFilters = {
      ...(applications.length > 0
        ? { [groupId]: applications.map((data: any) => data.identifier) }
        : {}),
      ...selectedCategoryOptions
    };
    if (applications.length > 0 && Array.isArray(ariaFilters[groupId])) {
      ariaFilters[groupId] = ariaFilters[groupId].flat();
    }
    const API_PAYLOAD = {
      source: sources,
      query: searchText,
      view_type: 'document',
      filterSource: 'default',
      userDetails,
      filters: ariaFilters
    };
    resultsDispatch({
      type: ResultActions.SET_TOP_10_SUMMARY,
      value: []
    });
    resultsDispatch({ type: ResultActions.SET_RIA_RESPONSE_TITLE, value: 'Top Result' });
    resultsDispatch({ type: ResultActions.SET_SHOW_TOP_10_SUMMARY, value: false });
    await fetchData(API_PAYLOAD);
    setSelectedFilters(selectedCategoryOptions);
  };
  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const removeAriaShareFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('ariaShare')) {
      queryParams.delete('ariaShare');
      history.replace({
        search: queryParams.toString()
      });
    }
  };

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setShowDocumentModal(false);
    dispatch({ type: GlobalActions.SET_SHOW_NEW_FILTERS, value: true });
    removeAriaShareFromUrl();
  }, [setModalOpen]);

  const handleClearSearch = () => {
    resultsDispatch({
      type: ResultActions.SET_DOCUMENT_RESULTS,
      value: []
    });
    setNewFilters([]);
  };

  const handleSearch = useCallback(
    async (searchTextProp: string | null = null, searchId: string | null = null) => {
      const queryText = searchTextProp || searchText;
      if (isEmpty(queryText)) {
        return;
      }
      setIsLoading(true);

      const userDetails = {
        role: currentUser['custom:role'],
        userid: currentUser.sub,
        email: '',
        organization: currentUser['custom:organization'],
        tier: currentUser['custom:license']
      };
      const ariaFilters = {
        [groupId]: applications.map((data: any) => data.identifier)
      };
      if (applications.length > 0 && Array.isArray(ariaFilters[groupId])) {
        ariaFilters[groupId] = ariaFilters[groupId].flat();
      }
      const API_PAYLOAD = {
        source: sources,
        query: queryText,
        view_type: 'document',
        filterSource: 'default',
        userDetails,
        ...(applications.length > 0 ? { filters: ariaFilters } : {})
      };

      if (searchId) {
        await fetchShareSearchData(searchId, API_PAYLOAD);
      } else {
        await fetchData(API_PAYLOAD);
      }
    },
    [searchText]
  );

  const handleDidYouMeanTextClick = useCallback(
    (text: string) => {
      setSearchText(text);
      handleSearch(text);
    },
    [handleSearch]
  );

  const getAriaSharedSearch = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedPayload = urlParams.get('ariaShare');

    const searchPayload: any = decodeBase64ToObject(encodedPayload ?? '');

    const aria = {
      sharedSearchText: null,
      sharedSearchId: null
    };

    const isItAriaShare =
      searchPayload?.ariaSearchTerm && searchPayload?.isAriaOpen && searchPayload?.ariaSearchId;

    if (isItAriaShare && !showDocumentsModal) {
      aria.sharedSearchText = searchPayload?.ariaSearchTerm;
      aria.sharedSearchId = searchPayload?.ariaSearchId;
      setSearchText(searchPayload?.ariaSearchTerm);
      setShowDocumentModal(true);
    }

    return aria;
  };

  useEffect(() => {
    let totalDocumentCount = 0;
    let totalPagesCount = 0;
    if (docCount && docPageCount) {
      setDocumentCount(docCount);
      setPageCount(docPageCount);
    } else {
      Object.keys(resultsState.applicationResults).forEach((source: any) => {
        resultsState.applicationResults[source].results.forEach((result: any) => {
          totalDocumentCount += result.document_count;
          totalPagesCount += result.document_total_page_count;
        });
      });
      setDocumentCount(totalDocumentCount);
      setPageCount(totalPagesCount);
    }

    const { sharedSearchText, sharedSearchId } = getAriaSharedSearch();

    if (sharedSearchText && sharedSearchId) {
      handleSearch(sharedSearchText, sharedSearchId);
    }
  }, []);

  const handleAriaShare = () => {
    const newPayload = {
      ariaSearchTerm: searchText,
      isAriaOpen: true,
      ariaSearchId: resultsState?.searchIds?.ariaSearchId || ''
    };

    const encodedPayload: any = encodeObjectToBase64(newPayload);
    history.push({ search: `?ariaShare=${encodedPayload}` });
  };
  useEffect(() => {
    const selected = Object.entries(sources).map(([label, module]: any) => ({
      label,
      value: label,
      module: module.map((value: any) => ({ value }))
    }));
    setSelectedSources(selected);
  }, [sources]);

  return (
    <Box>
      {!showDocumentsModal ? (
        <Drawer
          sx={{
            '& >.MuiDrawer-paper': {
              height: '60vh',
              borderRadius: '16px',
              paddingX: '24px',
              paddingY: '32px',
              position: 'absolute'
            }
          }}
          anchor='bottom'
          open={modalOpen}
          onClose={handleModalClose}>
          <Box display='flex' flexDirection='column'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'stretch'
              }}>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: 'center',
                  alignSelf: 'center',
                  fontWeight: '700',
                  fontSize: '20px',
                  color: 'gray.800',
                  textTransform: 'capitalize'
                }}>
                Ask RIA
              </Typography>
              <Tooltip title='Close'>
                <IconButton
                  onClick={handleModalClose}
                  sx={{ position: 'absolute', right: '24px', top: '26px' }}>
                  <Close />
                </IconButton>
              </Tooltip>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center'>
              <Divider
                sx={{
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: 'gray.400',
                  borderRadius: '2px',
                  mt: 1,
                  width: '64px'
                }}
              />
            </Box>
            <Box
              sx={{
                paddingTop: '64px',
                alignSelf: 'center',
                width: { xs: '762px', md: '762px', lg: '794px' },
                minWidth: '762px',
                maxWidth: '794px'
              }}>
              <Stack spacing={1}>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleSearch();
                  }}>
                  <Box
                    sx={{
                      ...styles.searchBarAria,
                      borderBottomLeftRadius: sourceDropDownOpen ? 0 : 15
                    }}>
                    <Stack direction='row' alignItems='center'>
                      <Divider orientation='vertical' flexItem sx={{ marginY: 1.5 }} />
                      <Box flex={1}>
                        <SearchBarAutoComplete
                          id='cdp-search-input'
                          value={searchText}
                          label={`Search here from ${documentCount} documents and ${pageCount} pages`}
                          options={searchSuggestions}
                          loading={isSuggestionsLoading}
                          onOpen={handleAutoCompleteOpen}
                          open={isSearchOpen}
                          onClose={handleAutoCompleteClose}
                          filterOptions={undefined}
                          onInputChange={handleKeyPress}
                          setSearch={value => setSearchText(value)}
                          clearSearchSuggestions={clearSearchSuggestions}
                          handleSearch={handleSearch}
                          disableSearch={isLoading}
                        />
                      </Box>
                      <Button
                        type='submit'
                        size='small'
                        startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                        sx={styles.searchButton}
                        disabled={isLoading}>
                        Search
                      </Button>
                    </Stack>
                  </Box>
                </form>
              </Stack>
            </Box>
            {isLoading && (
              <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                {' '}
                <CircularProgress color='success' />
              </Box>
            )}
          </Box>
        </Drawer>
      ) : (
        <Drawer
          sx={{
            '& >.MuiDrawer-paper': {
              height: '96vh',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              paddingX: '24px',
              paddingY: '32px',
              position: 'absolute',
              overflowY: 'hidden'
            }
          }}
          anchor='bottom'
          onClose={handleModalClose}
          open={showDocumentsModal}>
          <Box display='flex' flexDirection='column'>
            <Stack direction='row' justifyContent='space-between'>
              <Box sx={{ padding: '16px', width: '60%' }} display='flex' flexDirection='row'>
                <Box pr={2} alignSelf='center'>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: '20px',
                      color: 'gray.800',
                      textTransform: 'capitalize'
                    }}>
                    AskRIA
                  </Typography>
                </Box>
                <Box m='auto 0'>
                  <Divider orientation='vertical' sx={{ height: '20px', borderColor: '#E0E0E0' }} />
                </Box>
                <Box
                  pl={2}
                  sx={{
                    width: '80%'
                  }}>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      handleSearch();
                    }}>
                    <SearchBarAutoComplete
                      id='documents-modal'
                      value={searchText}
                      label={`Search here from ${documentCount} documents and ${pageCount} pages`}
                      options={searchSuggestions}
                      loading={isSuggestionsLoading}
                      onOpen={handleAutoCompleteOpen}
                      open={isSearchOpen}
                      onClose={handleAutoCompleteClose}
                      filterOptions={undefined}
                      onInputChange={handleKeyPress}
                      setSearch={value => setSearchText(value)}
                      clearSearchSuggestions={clearSearchSuggestions}
                      handleSearch={handleSearch}
                      disableSearch={isLoading}
                      type='nav'
                      handleClearSearch={handleClearSearch}
                    />
                  </form>
                </Box>
              </Box>
              <Box m='auto 0'>
                <Tooltip title='Close'>
                  <IconButton onClick={handleModalClose}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
            <Divider
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray.200',
                mt: 1,
                marginX: '-24px'
              }}
            />
            <Box>
              {!isLoading ? (
                <DocumentsView
                  showSourceDropdown={false}
                  onDidYouMeanTextClick={handleDidYouMeanTextClick}
                  handleAriaShare={handleAriaShare}
                  renderedInPopup
                />
              ) : (
                <Loading />
              )}
            </Box>
          </Box>
          {!resultsState?.selectedDocument && (
            <NewFilters
              data={newFilters as Category[]}
              applyFilters={applyFilters}
              isLoading={isLoading}
              selectedFilters={selectedFilters}
            />
          )}
        </Drawer>
      )}
    </Box>
  );
};

export default React.memo(AriaDrawerCore);
