const styles = {
  search: {
    width: 'inherit',
    border: 'none',
    outline: 'none',
    display: 'flex',
    fieldset: {
      display: 'none'
    },
    '& .MuiTextField-root': {
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '0 ',
      paddingBottom: '0 '
    }
  },
  searchNav: {
    outline: 'none',
    display: 'flex',
    borderRadius: 2,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
    fieldset: {
      display: 'none'
    },
    '& .MuiTextField-root': {
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '0 ',
      paddingBottom: '0 '
    }
  },
  searchButton: {
    width: '24px',
    height: '24px',
    color: 'white.main',
    background: 'linear-gradient(to right, #3C999A, #47A836)',
    padding: '4.8px',
    borderRadius: '4.8px',
    '&:hover': { background: 'linear-gradient(to right, #358788, #3f9530)' },
    '&:disabled': {
      background: 'linear-gradient(to right, #BDBDBD, #BDBDBD)'
    },
    '& svg': {
      fontSize: '14.4px !important'
    }
  },
  searchButtonAria: {
    color: 'gray.500',
    fontSize: 16,
    padding: '4px'
  },
  greyIcon: {
    color: 'gray.400',
    fontSize: 15
  },
  normalText: {
    flexGrow: 1
  },
  greenText: {
    color: 'primary.700',
    flexGrow: 1
  }
};

export default styles;
